import actionTypes from "../actions/actionTypes";

const initialState = {
  userdata: {},
  expiresIn:0,
  authenticated:false,
  logout:false
};


const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USER_SUCCESS:
      return {
        ...state,
        userdata: action.payload.userdata,
      };
      case actionTypes.UPDATE_AUTHENTICATED_STATUS:
        return {
          ...state,
          authenticated: action.payload.authenticated,
        };
      case actionTypes.SET_SHOW_LOGOUT:
        return {
          ...state,
          logout: action.payload.logout,
        };
    case actionTypes.SET_EXPIRATION:
      return {
        ...state,
        expiresIn:action.payload.expiresIn
      }
    default:
      return state;
  }
};

export default authReducer;
