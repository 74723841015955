import $ from "jquery";
import React from "react";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const Orders = React.lazy(() => import("./Pages/Orders/Orders"));
const Cakes = React.lazy(() => import("./Pages/Cakes/Cakes"));
const Gifts = React.lazy(() => import("./Pages/Gifts/Gifts"));
const BankAccountss = React.lazy(() => import("./Pages/BankAccounts/BankAccounts"));
const WithDrawals = React.lazy(() => import("./Pages/WithDrawal/WithDrawals"));

const Settings = React.lazy(() => import("./Pages/Settings/Settings"));
const Processing = React.lazy(() => import("./Pages/Orders/Processing/Processing"));
const Completed = React.lazy(() => import("./Pages/Orders/Completed/Completed"));
const AwaitingConfirmation = React.lazy(() => import("./Pages/Orders/AwatingConfirmation/AwaitingConfirmation"));


const routes = [
  {
    path: "/home/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/home/orders",
    exact: true,
    name: "Orders",
    component: Orders,
  },
  {
    path: "/home/cakes",
    exact: true,
    name: "Cakes",
    component: Cakes,
  },
  {
    path: "/home/gifts",
    exact: true,
    name: "Gifts",
    component: Gifts,
  },
  {
    path: "/home/bank-accounts",
    exact: true,
    name: "BankAccounts",
    component: BankAccountss,
  },
  {
    path:'/home/withdrawals',
    exact:true,
    name:'WithDrawals',
    component:WithDrawals
  },
  {
    path: "/home/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
  // {
  //   path: "/home/orders/processing",
  //   exact: true,
  //   name: "Settings",
  //   component: Processing,
  // },
  // {
  //   path: "/home/orders/awaiting-confirmation",
  //   exact: true,
  //   name: "Settings",
  //   component: AwaitingConfirmation,
  // },
  // {
  //   path: "/home/orders/completed",
  //   exact: true,
  //   name: "Settings",
  //   component: Completed,
  // },
];

export default routes;
