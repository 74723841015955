import React, { Component } from "react";
import "./PreLoader.css";

const PreLoader = () =>{
	
		return (
			<div className="preloader2"  style={{marginTop:`${window.innerHeight/2.2}px`}}>
		        <div className="loader">
		            <div className="loader__figure"></div>
		            <p className="loader__label">Memo Vendor</p>
		        </div>
		    </div>
			)
	}


export default PreLoader;
