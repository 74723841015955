import actionTypes from "../actions/actionTypes";

const initialState = {
  allBankAccounts:[]
};


const bankAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ALL_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        allBankAccounts: action.payload.allBankAccounts,
      };
     default:
      return state;
  }
};

export default bankAccountsReducer;
