import React, { Suspense } from "react";
import { connect } from "react-redux";
import {
  Redirect, Route,
  Switch,
  withRouter
} from "react-router-dom";
import "../../assets/scss/style.scss";
import * as CommonFunctions from "../../CommonFunctons";
import CreateAccount from "../../Pages/Authentication/CreateAccount/CreateAccount";
import ForgotPassword from "../../Pages/Authentication/ForgotPassword/ForgotPassword";
// import "../../css/bootstrap.min.css";
import SignIn from "../../Pages/Authentication/SignIn/SignIn";
import VerifyAccount from "../../Pages/Authentication/VerifyAccount/VerifyAccount";
import VerifyNewLoginAccount from "../../Pages/Authentication/VerifyAccount/VerifyNewLoginAccount";
import Aux from "../hoc/_Aux";
import Loader from "../Loader";
import ScrollToTop from "../ScrollToTop";
import AuthenticatedLayout from "./AuthenticatedLayout";

export class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount(){
  //   const authToken = localStorage.getItem('V-AUTH');
  //   if(this.props.history.location.pathname.includes('home')){
  //   if(this.props.authenticated  === true && this.props.userdata.token && authToken !== null ){
  //     this.props.history.push('/home/dashboard')
  //   }else{
  //     appHelpers.toastError(4000,'You are not unthenticated, please login again')
  //     this.props.history.push('/')
  //   }
  // }else{
  //   if(this.props.authenticated  === true && this.props.userdata.token && authToken !== null ){
  //     this.props.history.push('/home/dashboard')
  //   }
  // }
  }
  // componentDidMount() {
  //   if (!this.props.location.pathname.includes("home")) {
  //     document.body.style.backgroundColor = "white";
  //   }
  // }
  render() {
    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* All unthenticated Routes will go here */}
              {/* login, reset-password, update-password */}

              <Route exact path="/">
                <Redirect to="/login" />
              </Route>

              <Route
                path={"/login"}
                exact
                render={(props) => (
                  <SignIn
                  {...this.props}
                  {...props}
                  validator={CommonFunctions.validator}
                  validatorAll={CommonFunctions.validatorAll}
                />
                )}
              />
<Route
                path={"/create-account"}
                exact
                render={(props) => (
                  <CreateAccount
                    {...this.props}
                    {...props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />
              <Route
                path={"/forgot-password"}
                exact
                render={(props) => (
                  <ForgotPassword
                    {...this.props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />
              <Route
                path={"/verify-account"}
                exact
                render={(props) => (
                  <VerifyAccount
                    {...this.props}
                    {...props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />
              <Route
                path={"/verify-account-auth"}
                exact
                render={(props) => (
                  <VerifyNewLoginAccount
                  {...this.props}
                  validator={CommonFunctions.validator}
                  validatorAll={CommonFunctions.validatorAll}
                />
                )}
              />
              {/* This layout with house a layout containing all authenticated routes */}
              <Route
                path="/home"
                render={(props) => (
                  <AuthenticatedLayout {...this.props} {...props} />
                )}
              />

              {/* <Route
                render={(props) => <NotFound {...this.props} {...props} />}
              /> */}
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userdata: state.authReducer.userdata,
    authenticated: state.authReducer.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch, // ← Add this
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Layout));
