export const loginErrors = {
  email: "",
  password: "",
  all: new Set(),
};
export const addotpErrors = {
  otp: "",
  all: new Set(),
};
export const bankaccountErrors = {
  bank_id: "",
  account_no: "",
  account_name: "",
  all: new Set(),
};
export const createwithdrawalErrors = {
  bank_account_id: "",
  amount_requested: "",
  all: new Set(),
};
export const editbankaccountErrors = {
  bank_id: "",
  account_no: "",
  account_name: "",
  all: new Set(),
};
export const addCakeErrors = {
  name: "",
  description: "",
  baseprice: "",
  all: new Set(),
};
export const addGiftErrors = {
  name: "",
  description: "",
  price: "",
  all: new Set(),
};
export const editGiftErrors = {
  name: "",
  description: "",
  price: "",
  all: new Set(),
};
export const resetPasswordErrors = {
  password: "",
  confirmPassword: "",
  rcode: "",
  all: new Set(),
};
export const createAccountErrors = {
  email: "",
  password: "",
  companyName: "",
  firstName: "",
  lastName: "",
  all: new Set(),
};
export const forgotPasswordErrors = {
  email: "",
  all: new Set(),
};
export const changePasswordErrors = {
  password: "",
  confirmPassword: "",
  all: new Set(),
};

export const validateAddCake = (files, files1, sizes) => {
  let resObj = {};
  if (sizes?.length === 1) {
    if (sizes?.[0] && sizes?.[0]?.size === "") {
      resObj["status"] = false;
      resObj["value"] = "Cake size is required";
      return resObj;
    }
    if (sizes?.[0] && sizes?.[0]?.price === 0) {
      resObj["status"] = false;
      resObj["value"] = "Cake price is required";
      return resObj;
    }
  } else {
    for (let i in sizes) {
      let size = sizes[i].size;
      let price = sizes[i].price;
      if (price === 0 || isNaN(price)) {
        resObj["status"] = false;
        resObj["value"] = "Price is required";
        return resObj;
      }
      if (size === "") {
        resObj["status"] = false;
        resObj["value"] = "Size is required";
        return resObj;
      }
    }
  }
  if (files.length === 0) {
    resObj["status"] = false;
    resObj["value"] = "At least two(2) images are required";
    return resObj;
  }

  if (files1.length === 0) {
    resObj["status"] = false;
    resObj["value"] = "At least two(2) images are required";
    return resObj;
  }

  resObj["status"] = true;
  resObj["value"] = "Validations Passed";
  return resObj;
};

export const validateAddGift = (files, files1) => {
  let resObj = {};
  if (files.length === 0) {
    resObj["status"] = false;
    resObj["value"] = "At least two(2) images are required";
    return resObj;
  }

  if (files1.length === 0) {
    resObj["status"] = false;
    resObj["value"] = "At least two(2) images are required";
    return resObj;
  }
  resObj["status"] = true;
  resObj["value"] = "Validations Passed";
  return resObj;
};

export const validateEditGift = (files, files1) => {
  let resObj = {};
  if (files.length === 0 || files?.[0].preview === undefined) {
    resObj["status"] = false;
    resObj["value"] = "At least two(2) images are required";
    return resObj;
  }

  if (files1.length === 0 || files1?.[0].preview === undefined) {
    resObj["status"] = false;
    resObj["value"] = "At least two(2) images are required";
    return resObj;
  }
  resObj["status"] = true;
  resObj["value"] = "Validations Passed";
  return resObj;
};
