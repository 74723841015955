import { 
  VENDORS,
  GET_STATES
 } from "../Constants";
import Axios, { getSingleUrl } from "./axios";


export const UpdateVendorApiService = (data) => {
  const id = data.id
  delete data.id
  const payload = data
  return Axios.put(`${getSingleUrl(VENDORS)}/${id}`, payload);
};


export const GetStates = () => {
  return Axios.get(`${getSingleUrl(GET_STATES)}`)
}

export const GetCities = id => {
  return Axios.get(`${getSingleUrl(`states/${id}?include=cities`)}`)
}
