import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Colors } from '../../Constants';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: Colors.Yellow,
  },
}))(LinearProgress);



const CustomLinearProgress =({value}) => {

  return (
      <BorderLinearProgress variant="determinate" value={value} />
  );
}

export default CustomLinearProgress;
