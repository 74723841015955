import { toast as hotToast } from "react-hot-toast";
import SimpleCryptp from "simple-crypto-js";

export default hotToast

export const presets = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};


export const appHelpers = {
  getDefaultAuth: async () => {
    const encrypyValue = localStorage.getItem("V-AUTH");
    try {
      const token = appHelpers.cryptographyService().decrypt(encrypyValue);
      return token;
    } catch (e) {
      return null;
    }
  },
  getLandingDomain: () => {
    if (process.env.NODE_ENV !== "development") {
      return process.env.REACT_APP_LANDING_PORTAL;
    } else {
      return "http://localhost:3007/";
    }
  },
  toCapitalLetters: (value) => {
    if (typeof value === "string") {
      return value.toLocaleUpperCase();
    }
  },
  trimEvery: (t) => {
    return t.replace(/\s/g, "")
  },

  formatLargeNumbers: (x) => {
    if (isNaN(x)) return x;

    if (x < 9999) {
      return x;
    }

    if (x < 1000000) {
      return Math.round(x / 1000) + "K";
    }
    if (x < 10000000) {
      return (x / 1000000).toFixed(2) + "M";
    }

    if (x < 1000000000) {
      return Math.round(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return Math.round(x / 1000000000) + "B";
    }

    return "1T+";
  },
  
  numberWithCommas: (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  

  filterByValue: (array, index) => {
    return array.filter((o) => Object.keys(o).some((k) => o[k] === index));
  },

  createRandomString: () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  },

  truncateEmail: (email) => {
    if (email) {
      if (email.length > 20) {
        return email.substring(0, 20) + "...";
      }
      return email;
    } else {
      return "";
    }
  },

  formatEnum(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
      }
    })
  },

  formatCustomDataEnum(data, keyName) {
    if (data) {
      return data.map((item, index) => {
        return {
          label: item[keyName],
          value: item.id,
        }
      })
    }
  },
  toastSuccess(duration = 4500, message) {
    hotToast.success(message, {
      id: "default",
      style: {
        border: "1px solid #4caf50",
        padding: "16px",
        color: "#4caf50",
      },
      position: "top-center",
      duration: duration,
      iconTheme: {
        primary: "#4caf50",
        secondary: "#fff",
      },
    });
  },

  toastError(duration = 4500, message) {
    hotToast.error(message, {
      id: "default",
      style: {
        border: "1px solid #f44336",
        padding: "16px",
        color: "#f44336",
      },
      position: "top-center",
      duration: duration,
      iconTheme: {
        primary: "#f44336",
        secondary: "#fff",
      },
    });
  },
  scrambleEmail : (email) => {
    if (email) {
        let firstPart = email.slice(0, 2)
        let lastPart = email.slice(email.lastIndexOf("@"), email.length);
        return `${firstPart}******${lastPart}`
    }
  },
  formatObjResponse(data) {
    let arr = []
    if (data) {
      // return data.map((item, index) => {
      for (const [key, value] of Object.entries(data)) {
        arr.push({
          label: key,
          value: value
        })
      }
    }
    return arr
  },
  
  resetPagination(context, value) {
    context.setState((prevState) => ({
      [value]: {
        ...prevState[value],
        PageSize: 10,
      },
    }));
  },

  cryptographyService: () => {
    const simpleCrypto = new SimpleCryptp("T()o(){o){R}");
    return simpleCrypto;
  },

  isValidUrl: (string) => {
   
    // return true;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(string);
  
  },
  enterHandler: (id) => {
    window.addEventListener("keyup", function (event) {
      // 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        event.preventDefault();
        // Trigger the button element with a click
        let loginTrigger = document.querySelector(`#${id}`);
        if (loginTrigger && loginTrigger !== null) {
           loginTrigger.click();
        }
      }
    });
  },
};
