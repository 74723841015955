import React, { Component } from 'react';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';
import Logo from "../../../assets/images/memobrand.svg";
import actionTypes from "../../../logic/actions/actionTypes";
import Aux from "../../hoc/_Aux";


class NavLeft extends Component {

    render() {
        let iconFullScreen = ['feather'];
        iconFullScreen = (this.props.isFullScreen) ? [...iconFullScreen, 'icon-minimize'] : [...iconFullScreen, 'icon-maximize'];

        let navItemClass = ['nav-item'];
        if (this.props.windowWidth <= 575) {
            navItemClass = [...navItemClass, 'd-none'];
        }
        let dropdownRightAlign = false;
        if (this.props.rtlLayout) {
            dropdownRightAlign = true;
        }


        return (
            <Aux>
                <ul className="navbar-nav mr-auto">
                <div className="b-bg mobile-logo">
                                <img src={Logo} alt="Memo Logo" style={{marginLeft:0, width: "100px", height: "60px"}}/>
                            </div>
                   
                </ul>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFullScreen: state.navigation.isFullScreen,
        rtlLayout: state.navigation.rtlLayout
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFullScreen: () => dispatch({type: actionTypes.FULL_SCREEN}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(NavLeft));
