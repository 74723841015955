import Axios from 'axios';
// import dotenv from "dotenv";
import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from 'redux-persist/integration/react';
import Layout from './components/hoc/Layout';
import * as Constants from './Constants';
import "./css/black.css";
import "./css/style.css";
import './index.css';
import returnStoreAndPersistor from './logic/configureStore';
import reportWebVitals from './reportWebVitals';
import { Service } from './Services';
import { returnApiUrl } from './utils/globalUtil';


const {store} = returnStoreAndPersistor();
const {persistor} = returnStoreAndPersistor();
// dotenv.config();

// import 'bootstrap/dist/css/bootstrap.min.css';


const queryClient = new QueryClient();


        // const SERVICEBASEURI = process.env.REACT_APP_SERVICE_BASE_URL
        const SERVICEBASEURI = returnApiUrl()
        ReactDOM.render(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                <Router >
                    <>
                    {/* <App /> */}
                    <Layout
                        Constants={Constants}
                        Service={Service.bind(null, SERVICEBASEURI, Axios)}
                    />
                    <Toaster
                    toastOptions={{
                      style: {
                        maxWidth: "750px",
                        fontSize:'18px'
                      },
                    }}
                  />
                    </>
                </Router>
                </QueryClientProvider>
                </PersistGate>
            </Provider>
            , document.getElementById('root'));

    
    function noop() {}

    if (process.env.NODE_ENV !== 'development') {
      console.log = noop;
      console.warn = noop;
      console.error = noop;
    }
    reportWebVitals();

