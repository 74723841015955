// constants for api calls;
export const USERS = "users";
export const AUTH = "auth";
export const FORGOT = "forgot";
export const LOGIN = "login";
export const PASSWORD = "password";
export const OTP = "otp";
export const ME = "me";
export const CAKE_TOPPINGS = "cake_toppings";
export const CAKES = "cakes";
export const BANK_ACCOUNTS = 'bank_accounts'
export const VERIFY = "verify";
export const CAKE_FLAVOURS = "cake_flavours";
export const CAKE_SIZES = "cake_sizes";
export const CAKE_PICTURES = "cake_pictures";
export const GIFT_PICTURES = 'gift_pictures';
export const CAKE_ORDERS = "cake_orders";
export const ORDER_STATUS = "order_statuses";
export const BANKS = 'banks';
export const GIFTS = 'gifts';
export const VENDORS = 'vendors';
export const OTP_ACTIONS = 'otp_actions';
export const RESEND = 'resend';
export const WITHDRAWALS = 'withdrawals';
export const STATISTICS = 'statistics';
export const GET_STATES = 'countries/38?include=states';
// constants for context
export const Modules = {};

export const Colors = {
  WHITE: "#ffffff",
  Yellow: "#EEA530",
  Light_Yellow: "#FFF4EA",
  Light_Grey: "#ECECEC",
  Deep_Grey: "#787774",
  SuccessMemo: "#F4FFF4",
  CancelMemo: "#FFF2F2",
  PendingMemo: "#FFF4EA",
  TotalMemo: "#D28A17",
  TotalMemoOuter: "#EEA530",
  Red: "#F05D5B",
  LIGHT_GREEN: "#F4FFF4",
  GREEN: "#4CA655",
  LIGHT_ORANGE: "#FFF4EA",
  ORANGE: "#EEA530",
  REJECT: "#EE0020",
  THEME_BLACK: "#333333",
};

export const flavours = [
  {
    index: 1,
    label: "Strawberry",
    checked: false,
  },
  {
    index: 2,
    label: "Red velvet",
    checked: true,
  },
  {
    index: 3,
    label: "Strawberry",
    checked: false,
  },
  {
    index: 3,
    label: "Red velvet",
    checked: true,
  },
  {
    index: 4,
    label: "Chocolate",
    checked: false,
  },
  {
    index: 5,
    label: "Chocolate",
    checked: false,
  },
];

export const sizePriceInputFields = [{ size: "", price: 0 }];

export const toppings = [
  {
    index: 1,
    label: "Strawberry",
    checked: false,
  },
  {
    index: 2,
    label: "Red velvet",
    checked: true,
  },
  {
    index: 3,
    label: "Strawberry",
    checked: false,
  },
  {
    index: 3,
    label: "Red velvet",
    checked: true,
  },
  {
    index: 4,
    label: "Chocolate",
    checked: false,
  },
  {
    index: 5,
    label: "Chocolate",
    checked: false,
  },
  {
    index: 6,
    label: "Oreos",
    checked: false,
  },
];

export const monthsEntries = [
  {
    label: "This month",
    value: 0,
  },
  {
    label: "Today",
    value: 1,
  },
  {
    label: "Yesterday",
    value: 2,
  },
  {
    label: "Last month",
    value: 3,
  },
];
const DEMO = {
  BLANK_LINK: "#!",
};
export default DEMO;
