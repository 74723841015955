import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'


const CustomCheckBox = ({isChecked,name,color,label,onChange}) =>{
    console.log("color",color)
    return(
        <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={onChange}
                name={name}
                style ={{
                  color: color,
                }}
              />
            }
             label={label}
          />
    )
}
export default CustomCheckBox