import { Hidden } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import React, { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { appHelpers } from "../../../appHelpers";
import eye from "../../../assets/images/eye.svg";
// import facebook_logo from "../../../assets/images/facebook_logo.svg";
// import google from "../../../assets/images/google.svg";
import couple2 from "../../../assets/images/image2.png";
import login_arrow from "../../../assets/images/login-arrow.svg";
import logo from "../../../assets/images/logo.svg";
import mail from "../../../assets/images/mail.svg";
import padlock from "../../../assets/images/padlock.svg";
import person from "../../../assets/images/person.svg";
import CustomTilt from "../../../components/Animation/CustomTilt";
import CustomButton from "../../../components/Input/CustomButton";
import CustomCheckBox from "../../../components/Input/CustomCheckBox";
import CustomInput from "../../../components/Input/CustomInput";
import CustomLinearProgress from "../../../components/Loader/CustomLinearProgress";
import SpaceTop from "../../../components/Space/SpaceTop";
import { Colors } from "../../../Constants";
import { CreateAccountApiService } from "../../../services/auth";
import {GetCities, GetStates} from '../../../services/vendor';
import { handleError } from "../../../services/error-handler";
import { createAccountErrors } from "../../../utils/validation";
import usecommonStyles from "../genericAuthStyle";
import { useStyles } from "../genericAuthStyle";

const CreateAccount = (props) => {
  const hasError = useRef(null);

  const [showPassword, setshowPassword] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const authSelector = useSelector((state) => state.authReducer);
  const authData = authSelector && authSelector;
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    companyName: "",
    phone:"",
    state: "",
    address: "",
    city: ""
  });
  const [err, setError] = useState(createAccountErrors);
  const [loading, setLoading] = useState(false);
  const [disableLogin, setdisableLogin] = useState(false);
  const commonClasses = usecommonStyles();
  const classes = useStyles();
  const [agreed, setAgreed] = useState(false)
  const toggleShowPassword = () => {
    setshowPassword(!showPassword);
  };


  const getStates = () => {
    GetStates()
      .then(res => {
        const {success, data} = res.data
        setStates(data.states)
      })
      .catch(err => {
        // console.log(err)
      })
  }

  const getCities = id => {
    GetCities(id)
      .then(res => {
        const {success, data} = res.data
        setCities(data.cities)
      })
      .catch(err => {
        // console.log(err)
      })
  }

  const handleStateId = id => {
    // setStateID(prevState => prevState)
    getCities(id)
  }
  useEffect(() => {
    getStates()
  }, [])

  const { mutate, isLoading } = useMutation(CreateAccountApiService, {
    onSuccess: (res) => {
      const { success, data } = res.data;
      if (success) {
        history.push({
          pathname:"/verify-account",
          user_id:data.id
        });
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      appHelpers.toastError(
        4500,
        response?.message ?? "Something happened, please try again."
      );
    },
  });
  const handleInputChange =
    (input, label) =>
    ({ target: { value } }) => {
      setData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
      props.validator(
        { name: input, value: value, label: label },
        "Login",
        setError,
        err
      );
    };

  const handleNext = () => {
   
    props.validatorAll(
      [
        { name: "firstName", value: data.firstName, label: "Firstname" },
        { name: "lastName", value: data.lastName, label: "Lastname" },
        { name: "password", value: data.password, label: "Password" },
        { name: "email", value: data.email, label: "Email" },
        { name: "companyName", value: data.companyName, label: "companyName" },
      ],
      "CreateAccount",
      setError,
      err,
      hasError
    );
    if (!hasError.current) {
      setLoading(false);

      setdisableLogin(false);
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      setdisableLogin(false);
      setLoading(false);
      return;
    }
    if (err.email && err.password === "") {
      setLoading(false);
    }
    const payload = {
        "first_name": data.firstName,
        "last_name": data.lastName,
        "email": data.email,
        "password": data.password,
        'vendor': {
        "business_name": data.companyName,
        "default_commission":5,
        "state": data.state,
        "street_address": data.address,
        "alternative_address": data.address,
        "city": data.city
        }
    }
    if(agreed === false){
      appHelpers.toastError(
        4500,
        "Please accept the terms of use to proceed."
      );
      return
    }
    mutate({
      ...payload,
    });
  };


  const handleOpenLink = ()=>{
    window.open('https://landing.memo.africa/terms-of-use', '_blank').focus();
  }

  const handleTermsChange = (e)=>{
    setAgreed(e.target.checked)
  }
  return (
    <div className="container p-3">
      <div className="grouped-content">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <p>
          Have an account?&nbsp;
          <span>
            <Link className="memor" to="/login">
              <b>Sign in</b>
            </Link>
          </span>
        </p>
      </div>
      <CustomLinearProgress value={authData?.signupFlowValue} />
      <SpaceTop length={40} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <h3>
            <b>Create your account</b>
          </h3>
          <p className={commonClasses.subText}>
            Fill in the details below to get started with memo
          </p>
          <div>
            <SpaceTop length={70}/>
            {/* <div className="d-flex flex-wrap justify-content-between">
              <CustomButton
                show
                text="Google"
                style={{
                  backgroundColor: "#ea4335",
                  color: "white",
                  width: "45%",
                  padding: "26px",
                  border: "1px #395484",
                }}
                startIcon={
                  <img
                    className="mr-2"
                    style={{ width: "23px", height: "23px" }}
                    src={google}
                    alt="Google"
                  />
                }
              />
              <CustomButton
                show
                style={{
                  backgroundColor: "#395485",
                  color: "white",
                  padding: "26px",
                  width: "45%",
                  border: "1px #395484",
                }}
                startIcon={
                  <img
                    className="mr-2"
                    style={{ width: "23px", height: "23px" }}
                    src={facebook_logo}
                    alt="facebook"
                    width="40%"
                  />
                }
                text="Facebook"
              />
            </div> */}
            {/* <div className="d-flex flex-row">
              <HorizonalRuler />
              &nbsp;&nbsp;&nbsp;
              <p>or</p>&nbsp;&nbsp;&nbsp;
              <HorizonalRuler />
            </div> */}
            <CustomInput
              errorText={err.firstName}
              showError={err.firstName.length > 0}
              value={data.firstName}
              onChange={handleInputChange("firstName", "Firstname")}
              startIcon={<img src={person} alt="person" />}
              placeholder="Your first name"
            />
            <CustomInput
              errorText={err.lastName}
              showError={err.lastName.length > 0}
              value={data.lastName}
              onChange={handleInputChange("lastName", "Lastname")}
              startIcon={<img src={person} alt="person" />}
              placeholder="Your last name"
            />
            <CustomInput
              errorText={err.companyName}
              showError={err.companyName.length > 0}
              value={data.companyName}
              onChange={handleInputChange("companyName", "Company name")}
              startIcon={<img src={person} alt="person" />}
              placeholder="Company name"
            />
            <CustomInput
              errorText={err.email}
              showError={err.email.length > 0}
              value={data.email}
              onChange={handleInputChange("email", "Business name")}
              startIcon={<img src={mail} alt="mail" />}
              placeholder="Business email"
            />

            <CustomInput
              errorText={err.password}
              showError={err.password.length > 0}
              value={data.password}
              type={showPassword ? "text" : "password"}
              onChange={handleInputChange("password", "Password")}
              startIcon={<img src={padlock} />}
              endIcon={
                !showPassword ? (
                  <img
                    src={eye}
                    onClick={toggleShowPassword}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    onClick={toggleShowPassword}
                    style={{ cursor: "pointer", color: "#979694" }}
                  />
                )
              }
              placeholder="Password"
            />

            <label>State</label>

            <TextField
              value={data.state}
              select
              variant='outlined'
              fullWidth
              onChange={handleInputChange('state', 'state')}
              className={classes.textField}
            >
              {states?.map(state => (
                <MenuItem
                  value={state.name}
                  key={'state-' + state.name}
                  onClick={() => handleStateId(state.id)}
                >
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
            <SpaceTop length={25} />
            <label>City</label>

            <TextField
              value={data.city}
              select
              variant='outlined'
              fullWidth
              onChange={handleInputChange('city', 'City')}
              // style={{background: '#fff', fontSize: '8px !important'}}
              className={classes.textField}
            >
              {cities?.map(city => (
                <MenuItem value={city.name} key={'city-' + city.name}>{city.name}</MenuItem>
              ))}
            </TextField>
            <SpaceTop length={25} />
            <label>Address</label>

            <CustomInput
              value={data.address}
              fullWidth
              onChange={handleInputChange('address', 'address')}
            />
          </div>
          &nbsp;
          {/* <Link
            className="text-alone cursor-pointer"
            to="/terms-of-use"
          >
            Terms of use
          </Link> */}
          <SpaceTop length={5} />
          <div className="d-flex justify-content-start flex-wrap">
            <div><CustomCheckBox onChange={handleTermsChange} label="" color={Colors.Yellow}/></div>
            <div style={{marginTop:'10px',marginLeft:-10}}><a
            className=" cursor-pointer"
            href="#!"
            onClick={handleOpenLink}
            alt="Terms of user"
            rel="noreferrer"
          >
            Accept our terms of use policy
          </a></div>
            </div>
          <CustomButton
            className="mt-0"
            show
            style={{
              backgroundColor: "#eea350",
              color: "white",
              width: "100%",
            }}
            loading={isLoading}
            onClick={handleNext}
            endIcon={<img className="mr-2" src={login_arrow} alt="next" />}
            text="Next"
          />
        </Grid>
        <Grid item lg={1}></Grid>
        <Hidden xsDown>
        <Grid item xs={12} sm={6} className="text-center m-auto">
          <CustomTilt>
            <img src={couple2} alt="signin" style={{marginTop:'100px'}} className="" width="85%" />
          </CustomTilt>
        </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default CreateAccount;
