import { appHelpers } from "./appHelpers";


export const Service = (baseUrl, Axios, token, history) => {

  const instance = Axios.create({
    baseURL: baseUrl,
    headers: { 'Authorization': token },
    // you can add other headers here which has been passed from AuthorizedLayout as arguments
  });

instance.interceptors.response.use(response=>response,
  error=>{
    let status = error.response.status
    // debugger
    
    const url = error.response.config.url
    const module = url  && url.split("/")[0]
    if(status === 403){
      appHelpers.alertError(`Requested Resource '${module && module}' is Forbidden `)
     }
    else if (status === 401){
      appHelpers.alertError("Session expired. Login again")
      localStorage.clear();
      history.push("/login")
    }
    }
  );

  const getApiUrl = (controller, action) => {
    return `${controller}/${action}`;
  };

  const getRestUrl = (controller, action) => {
    return `${controller}/${action}`;
  };

  const getApiv1Url = (controller) => {
    return `${controller}`;
  };

  const getAllItems = (controller, action, data) => {
    return instance.post(getApiUrl(controller, action), data);
  };

  const getListItems = (controller, action,pageSize=10, pageNumber = 1, searchData) => {

    let  url = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;


    if(searchData && searchData.keyword !== null && searchData.keyword !== "" && searchData.keyword !== undefined){
      url+= `&keyword=${searchData.keyword}`
    }
    if(searchData && searchData.status !== null && searchData.status !== "" && searchData.status !== undefined){
      url+= `&status=${searchData.status}`
    }
    return instance.get(
      `${getRestUrl(controller, action)}${url}`
    );
  };
  const getListItemsById = (controller, action, pageSize=10, pageNumber = 1, searchData) => {
    let url;
    if(searchData.Id){
    url = `${searchData.Id && `/${searchData.Id}`}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    }
    if(searchData.clientId){
      url = `${searchData.clientId && `/${searchData.clientId}`}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
 
    }
    // debugger
    if(searchData && searchData.keyword !== null && searchData.keyword !== "" && searchData.keyword !== undefined){
      url+= `&keyword=${searchData.keyword}`
    }
    if (searchData && searchData.startDate !== null && searchData.startDate !== "" && searchData.startDate !== undefined) {
      url += `&startDate=${searchData.startDate}`
    }
    if ( searchData.isAscending !== null && searchData.isAscending !== "" && searchData.isAscending !== undefined) {
      // url += `&isAscending=${searchData.isAscending}`
      // we want it to be fifo
      url += `&isAscending=${false}`
    }
    if (searchData && searchData.roleId !== null && searchData.roleId !== "" && searchData.roleId !== undefined) {
      url += `&roleId=${searchData.roleId}`
    }
    if (searchData && searchData.endDate !== null && searchData.endDate !== "" && searchData.endDate !== undefined) {
      url += `&endDate=${searchData.endDate}`
    }
    if (searchData && searchData.keyword !== null && searchData.keyword !== "" && searchData.keyword !== undefined) {
      url += `&keyword=${searchData.keyword}`
    }
    if (searchData && searchData.status !== null && searchData.status !== "" && searchData.status !== undefined) {
      url += `&status=${searchData.status}`
    }
    return instance.get(`${getRestUrl(controller, action)}${url}`);
  };

  const getReduxItemsFilter = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    searchData
  ) => {
    let url = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}${url}`
    );
  };

  const getReduxItems = (controller,includes) => {
    let url = ``
    if(includes){
      url = `?include=`
    if(includes.role){
      url += `${includes.role}`
    }
  }

    return instance.get(`${getApiv1Url(controller)}${url}`);
  };

  const getReduxItemsById = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    id
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };

  const getReduxItemsByTwoIds = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    idOne,
    idTwo
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}/${idOne}/${idTwo}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };

  const createItem = (data, controller, action) => {
    return instance.post(getApiUrl(controller, action), data);
  };

 
  const editItem = (controller, data) => {
    return instance.put(`${getApiv1Url(controller)}`, data);
  };

  const deleteItem = (controller, id) => {
    return instance.delete(`${getApiv1Url(controller)}/${id}`);
  };
  const getInfo = (controller, action) => {
    return instance.get(`${getApiUrl(controller, action)}?include=vendor,bankAccounts,cards`);
  };
  
  const userLogin = (data, controller, action) => {
    return instance.post(getRestUrl(controller, action), data);
  };

  const getUserById = (controller, id) => {
    return instance.get(`${getApiv1Url(controller)}/${id}`);
  };
  const toggleIsActive = (controller, action, id) => {
    return instance.post(`${getRestUrl(controller, action)}/${id}`)
  }

  const createItemV1 = (data, controller) => {
    return instance.post(getApiv1Url(controller), data);
  };

  const toggleEnabled = (controller, action, id) => {
    return instance.put(`${getRestUrl(controller, action)}/${id}`)
  }

  const uploadImage = (controller, action, id,data) => {
    return instance.put(`${getRestUrl(controller, action)}/${id}`, data);
  }

  // all other api service functions can be created here which is then accessible to other routes and components in the Authorized Layout

  return {
    uploadImage,
    getInfo,
    getReduxItemsByTwoIds,
    deleteItem,
    getReduxItemsById,
    getListItemsById,
    getListItems,
    toggleIsActive,
    getReduxItemsFilter,
    getAllItems,
    getReduxItems,
    createItem,
    userLogin,
    editItem,
    getUserById,
    createItemV1,
    toggleEnabled,
  };
};
