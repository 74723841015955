import { appHelpers } from '../../appHelpers';
import actionTypes, { fetchAllBankAccountsuccess, fetchAllBanksuccess, saveflavoursSuccess, savetoppingsSuccess, saveUserData } from './actionTypes';

/* Utility functions */

const getPayloadFromData = res => {
  let result = {};
  const data = res.data;
  if (data) { /* Listed out possible scenarios of how the response data might be wrapped */
    const payload = data.data || data.result;
    result = payload || data;
  }
  return result;
};

const getDataFromResult = res => {
  if (Array.isArray(res)) {
    const result = [];
    res.map(obj =>
      result.push(getPayloadFromData(obj))
    );
    return result;
  }
  if (res) {
    return getPayloadFromData(res);
  }
};
/* Utility functions */

export function requestData(subtype) {
  return {
    type: actionTypes.BEGIN_REQUEST,
    subtype
  };
}

export function requestSuccess(data) {
  return {
    type: actionTypes.REQUEST_SUCCESS,
    data
  };
}
export function requestFailure(error) {
  return {
    type: actionTypes.REQUEST_FAILURE,
    error
  };
}

/** Formerly known as fetchData */
export function promiseAction(apiRequest, action) {
  return dispatch => {
    dispatch({type: actionTypes.BEGIN_REQUEST});
    apiRequest.then(res => {
      console.log('Result data', res);
      action.payload = getDataFromResult(res);
      requestSuccess(action.payload);
      dispatch(action);
    }).catch(err => {
      action.error = true; // We might want to submit the error messages
      action.payload = err.response || {};
      requestFailure(err);
      dispatch(action);
    });
  };
}

export function fetchAllBanks(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.BANKS)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.data) {
              dispatch(fetchAllBanksuccess(appHelpers.formatCustomDataEnum(data.data,'name')));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllCakeFlavours(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CAKE_FLAVOURS)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.data) {
              // dispatch(saveflavoursSuccess(appHelpers.formatCustomDataEnum(data.data,'name')));
              dispatch(saveflavoursSuccess(data.data));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
export function fetchAllCakeToppings(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CAKE_TOPPINGS)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.data) {
              // dispatch(savetoppingsSuccess(appHelpers.formatCustomDataEnum(data.data,'name')));
              dispatch(savetoppingsSuccess(data.data));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllBankAccounts(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.BANK_ACCOUNTS)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.data) {
              dispatch(fetchAllBankAccountsuccess(appHelpers.formatCustomDataEnum(data.data,'account_name')));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllOrderStatuses(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.ORDER_STATUS)
        .then((res) => {
          const { data } = res;
          if (data) {
            const orderStatuses = data.data
            if (data.data) {
              dispatch({
                type: actionTypes.SAVE_ORDER_STATUS_SUCCESS,
                payload: { orderStatuses },
              })
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAndUpdateUserDetails(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getInfo(Constants.USERS,Constants.ME)
        .then((res) => {
          const { data } = res;
          if (data) {
            dispatch(saveUserData(data.data))
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
