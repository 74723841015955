import actionTypes from "../actions/actionTypes";

const initialState = {
  orderStatuses: [],
};


const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatuses: action.payload.orderStatuses,
      };
    default:
      return state;
  }
};

export default orderReducer;
