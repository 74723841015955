import { Hidden, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { appHelpers } from "../../../appHelpers";
import couple from "../../../assets/images/image3.png";
import logo from "../../../assets/images/logo.svg";
import mail from "../../../assets/images/mail.svg";
import CustomTilt from "../../../components/Animation/CustomTilt";
import CustomButton from "../../../components/Input/CustomButton";
import CustomInput from "../../../components/Input/CustomInput";
import SpaceTop from "../../../components/Space/SpaceTop";
import { ForgotPasswordApiService } from "../../../services/auth";
import { handleError } from "../../../services/error-handler";
import { forgotPasswordErrors } from "../../../utils/validation";
import usecommonStyles from "../genericAuthStyle";

const useStyles = makeStyles((theme) => ({
  resetPasswordImage: {
    width: "120%",
  },
}));
const ForgotPassword = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const commonClasses = usecommonStyles()
  const hasError = useRef(null);
  const [data, setData] = useState({
    email: "",
  });
  const [loading, setloading] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [err, setError] = useState(forgotPasswordErrors);
 

  const handleInputChange =
    (input, label) =>
    ({ target: { value } }) => {
      setData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
      props.validator(
        { name: input, value: value, label: label },
        "ForgotPassword",
        setError,
        err
      );
    };
  const handleLogin = () => {
    setloading(true)
    setdisabled(true)
    props.validatorAll(
      [
        { name: "email", value: data.email, label: "Email" },
      ],
      "ForgotPassword",
      setError,
      err,
      hasError
    );
    if (!hasError.current) {
      setloading(false)
      setdisabled(false)
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      setloading(false)
      setdisabled(false)
      return;
    }
     ForgotPasswordApiService({...data})
    .then((res)=>{
      appHelpers.toastSuccess(4500,`${res.data.message}`)
      history.push({
        pathname: '/reset-password',
        state: { email: data.email }
      })
    })
    .catch((error)=>{
      const { response,errorString } = handleError(error);
      appHelpers.toastError(4500,errorString ?? response?.message ?? 'Something happened, please try again.')
   
    })
  };
  
  return (
    <div className="container p-3">
      <div className="group-content">
      <Link to="/"><img src={logo} alt="logo" /></Link>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <SpaceTop length={40}/>
          <h3>
            <b>Forgot Password</b>
          </h3>
          <p className={commonClasses.subText}>Enter your email address to reset password</p>
          <div>
            <CustomInput
              errorText={err.email}
              showError={err.email.length > 0}
              value={data.email}
              onChange={handleInputChange("email", "Email")}
              startIcon={<img src={mail} alt="Mail" />}
              placeholder="Email address"
            />
          </div>
          &nbsp;
          <CustomButton
            className="mt-0"
            onClick={handleLogin}
            loading={loading}
            disabled={disabled}
            show
            style={{
              backgroundColor: "#eea350",
              color: "white",
              width: "100%",
            }}
            text="Reset password"
          />
        </Grid>
        <Grid item lg={1}></Grid>
        <Hidden xsDown>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CustomTilt>
          <img
            src={couple}
            alt="couple"
            className={classes.resetPasswordImage}
          />
          </CustomTilt>
        </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
