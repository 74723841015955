import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import { promiseMiddleware } from './middleware';
import { appSettings } from "./reducers/appSettings";
import authReducer from "./reducers/authReducer";
import bankAccountsReducer from './reducers/bankAccountsReducer';
import bankReducer from './reducers/bankReducer';
import flavourReducer from "./reducers/flavourReducer";
import navigation from "./reducers/navigation";
import orderReducer from "./reducers/orderReducer";
import toppingsReducer from "./reducers/toppingsReducer";

const rootReducer = combineReducers({
    appSettings,
    navigation,
    authReducer,
    orderReducer,
    bankReducer,
    bankAccountsReducer,
    toppingsReducer,
    flavourReducer
  });
 
const persistConfig = {
  key: 'root',
  storage,
  whitelist:['authReducer']
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)
 
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let store;
  if(process.env.NODE_ENV === 'development'){
  store = createStore(persistedReducer,composeWithDevTools(applyMiddleware(thunk, promiseMiddleware, logger)))
  }else{
    store = createStore(persistedReducer,composeWithDevTools(applyMiddleware(thunk, promiseMiddleware)))

  }
  let persistor = persistStore(store)
  return { store, persistor }
}