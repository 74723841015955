import actionTypes from "../actions/actionTypes";

const initialState = {
  allBanks:[]
};


const bankReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ALL_BANKS_SUCCESS:
      return {
        ...state,
        allBanks: action.payload.allBanks,
      };
     default:
      return state;
  }
};

export default bankReducer;
