import { Hidden } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { appHelpers } from "../../../appHelpers";
import couple from "../../../assets/images/image3.png";
import login_arrow from "../../../assets/images/login-arrow.svg";
import logo from "../../../assets/images/logo.svg";
import CustomTilt from "../../../components/Animation/CustomTilt";
import CustomButton from "../../../components/Input/CustomButton";
import SpaceTop from "../../../components/Space/SpaceTop";
import { saveUserData } from "../../../logic/actions/actionTypes";
import { OTPApiService } from "../../../services/auth";
import { handleError } from "../../../services/error-handler";
import usecommonStyles from "../genericAuthStyle";

const VerifyNewLoginAccount = (props) => {
  const location = useLocation();
  const history = useHistory();
  console.log("location", location);

  const commonClasses = usecommonStyles();
  const dispatch = useDispatch();
 
  const [val, setVal] = useState("");
  const otpMutate = useMutation(OTPApiService,{
    onSuccess: (res) => {
      const {  data ,status} = res;
      if (status === 200) {
        localStorage.setItem('V-AUTH',appHelpers.cryptographyService().encrypt(data?.token))
        dispatch(saveUserData(data));
        history.push("/home/dashboard");
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      console.log("Error", response.message);
      appHelpers.toastError(
        4500,
        response?.message ?? "Something happened, please try again."
      );
    },
  });

 

  const handleSubmit = () => {
    const joined = `${val.trim()}`;
    if (joined === "") {
      appHelpers.toastError(4500, "Please enter OTP code.");
       
      }else{
        otpMutate.mutate(
          {
            email:location?.state?.email,
            otp:joined
          }
        )
      }
    
  };
  const handleChange = (vals) => {
    setVal(vals);
  };
  return (
    <div className="container p-3">
      <div className="group-content">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <SpaceTop length={60} />
          <h3>
            <b>Verify your account</b>
          </h3>
          <p className={commonClasses.subText}>
            Input OTP sent to {appHelpers.scrambleEmail(location.state?.email)}{" "}
            to verify your account
          </p>
          <div className="inline-flex">
            <ReactCodeInput
              autoFocus
              fields={6}
              type="text"
              
              onChange={handleChange}
              className=" input-group memoCodeInput"
            />
          </div>

          <p className="mt-4">
            Wait for <b>5:00</b> to resend OTP
          </p>

          <CustomButton
            className="mt-2"
            show
            loading={otpMutate.isLoading}
            disabled={otpMutate.isLoading}
            onClick={handleSubmit}
            style={{
              backgroundColor: "#eea350",
              color: "white",
              width: "100%",
            }}
            endIcon={
              <img
                className="mr-2"
                style={{ width: "23px", height: "23px" }}
                src={login_arrow}
                alt="next"
              />
            }
            text="Next"
          />
        </Grid>
        <Grid item lg={1}></Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={12} md={4} lg={4} display={{ sm: "none" }}>
            <CustomTilt>
              <img src={couple} alt="signin" className="" width="120%" />
            </CustomTilt>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default VerifyNewLoginAccount;
