
export const handleError = (error) => {
  const { config, request, response } = error;

  let data = { config };
    if(response === undefined){
    return {
      response:{message: 'Check internet connection and try again',}
    };
  }
  if (response) {
    data = {
      status: response.status,
      headers: response.headers,
      response: response?.data || null,
      errorString: response?.data?.errors ?mergeErrors(response?.data?.errors) : response.data?.message,
        };
        if(response.status === 500 ) return {
          ...data,
          response:{message: 'An internal error occurred, please try again.'}
        }
    if (response.status === 404) return data;

    if (response.status === 401 && response.data.message === 'jwt expired') {
      //we'll logout here
      return;
    }

    return data;
  } else if (request) {
    return {
      ...data,
      ...error.request,
      errorString: mergeErrors(response.data.errors),
      message: 'Check internet connection',
    };
  } else {
    return {
       ...data, message: error.message ,
      errorString: response?.data?.errors ?mergeErrors(response?.data?.errors) : '',
    };
  }
};

const mergeErrors = (errors)=>{
  let errorString = ''
  for (const [key, value] of Object.entries(errors)) {
    errorString+= value
  }
  return errorString
}
