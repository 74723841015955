import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import React from "react";
import { Colors } from "../../Constants";


const useStyles = makeStyles((theme) => ({
  memoButton: {
    minWidth: '150px',
    height: '40px',
    color: 'white',
    fontStyle: 'normal',
    padding:theme.spacing(3),
    fontWeight: 'bold',
    textTransform:'capitalize',
    fontSize: '15px',
    lineHeight: '23px',
    alignItems: 'center',
    textAlign: 'center',
    background: Colors.Yellow,
    border: `0.5px solid ${Colors.Yellow}`,
    boxSizing: 'border-box',
    boxShadow:'0px',
    marginBottom:'12px',
    '&:hover': {
      background: Colors.Yellow,
      opacity:'0.8'
   },
  },
  roundedBorder:{
    borderRadius: '32px' ,
  },
  cancelType:{
    background: 'white',
    border: `2px solid ${Colors.Yellow}`,
    color: Colors.Yellow,
    '&:hover': {
      background: 'white',
      opacity:'0.8'
   },
  },
  rejectType:{
    border:`1px solid  ${Colors.REJECT}`,
    color:Colors.REJECT,
    backgroundColor:Colors.WHITE
  }
}));

export default function CustomButton({
  className = " mb-4 memoButton ",
  onClick,
  disabled,
  style,
  show,
  backgroundColor,
  type,
  shadow,
  endIcon,
  startIcon,
  loading,
  loadingText,
  text,
  roundedBorder = true
}) {
  const  classes = useStyles()
  // let className = "mb-4 memoButton "

  if (shadow === true) {
    className += " shadow-2 ";
  }
  if (type === "cancel") {
    className += " memoButtonCancel ";
  }
  if (backgroundColor) {
    className = "mb-4";
  } else {
    className += className;
  }
  let btnClass = clsx(classes.className, classes.memoButton,
    roundedBorder && classes.roundedBorder,
    type === 'cancel' && classes.cancelType,
    type === 'reject' && classes.rejectType
  );
  return (
    <>
      {/* {show&& <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={style}

    >
      {loading && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} {loadingText}
     {startIcon} {text} {endIcon}
    </button>} */}
      <Button
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        style={style}
        disabled={disabled}
        color="primary"
        
        className={btnClass}
      >
       {loading && <div style={{marginRight:'8px',marginTop:'5px'}}><CircularProgress size={20}  color="white" /></div>}  {loadingText} {text}
      </Button>
    </>
  );
}
