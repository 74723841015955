
const actionTypes = {
  APP_LOAD: "APP_LOAD",
  BEGIN_REQUEST: "BEGIN_REQUEST",
  COLLAPSE_MENU: "COLLAPSE_MENU",
  COLLAPSE_TOGGLE: "COLLAPSE_TOGGLE",
  FULL_SCREEN: "FULL_SCREEN",
  FULL_SCREEN_EXIT: "FULL_SCREEN_EXIT",
  CHANGE_LAYOUT: "CHANGE_LAYOUT",
  NAV_CONTENT_LEAVE: "NAV_CONTENT_LEAVE",
  NAV_COLLAPSE_LEAVE: "NAV_COLLAPSE_LEAVE",
  UPDATE_NAV_HEADER_COLOR: "UPDATE_NAV_HEADER_COLOR",
  CHANGE_TOP_NAV_MENU_ITEM: "CHANGE_TOP_NAV_MENU_ITEM",
  SAVE_USER_SUCCESS:"SAVE_USER_SUCCESS",
  SAVE_CURRENT_URL_SUCCESS:"SAVE_CURRENT_URL_SUCCESS",
  SET_EXPIRATION:"SET_EXPIRATION",
  SAVE_TOPPINGS_SUCCESS:"SAVE_TOPPINGS_SUCCESS",
  SAVE_ORDER_STATUS_SUCCESS:'SAVE_ORDER_STATUS_SUCCESS',
  SAVE_ALL_BANKS_SUCCESS:'SAVE_ALL_BANKS_SUCCESS',
  SET_SHOW_LOGOUT:'SET_SHOW_LOGOUT',
  UPDATE_AUTHENTICATED_STATUS:'UPDATE_AUTHENTICATED_STATUS',
  SAVE_ALL_BANK_ACCOUNTS_SUCCESS:'SAVE_ALL_BANK_ACCOUNTS_SUCCESS',
  SAVE_FLAVOURS_SUCCESS:'SAVE_FLAVOURS_SUCCESS'
};

// add as many actionTypes as necessary. (But be careful of memory leakages)

export const savetoppingsSuccess = (alltoppings) => ({
  type: actionTypes.SAVE_TOPPINGS_SUCCESS,
  payload: { alltoppings },
});
export const saveflavoursSuccess = (allflavours) => ({
  type: actionTypes.SAVE_FLAVOURS_SUCCESS,
  payload: { allflavours },
});
export const saveexpirationSuccess = (expiresIn) => ({
  type: actionTypes.SET_EXPIRATION,
  payload: { expiresIn },
});
export const saveuserDataSuccess = (userdata) => ({
  type: actionTypes.SAVE_USER_SUCCESS,
  payload: { userdata },
});

export const fetchAllBanksuccess = (allBanks) => ({
  type: actionTypes.SAVE_ALL_BANKS_SUCCESS,
  payload: { allBanks},
});

export const fetchAllBankAccountsuccess = (allBankAccounts) => ({
  type: actionTypes.SAVE_ALL_BANK_ACCOUNTS_SUCCESS,
  payload: { allBankAccounts},
});
export const saveCurrentUrlSuccess = (currentUrl) => ({
  type: actionTypes.SAVE_CURRENT_URL_SUCCESS,
  payload: { currentUrl },
});


// Redux Actions Api Calls


// Redux Actions Api Calls




//  other dispatches
export function saveExpiration(expiresIn) {
  return (dispatch) => {
    return (
      dispatch(saveexpirationSuccess(expiresIn))
    );
  };
}
export function saveUserData(data) {
  return (dispatch) => {
    return (
      dispatch(saveuserDataSuccess(data))
    );
  };
}

export function handleSaveAllToppings(alltoppings) {
  return (dispatch) => {
    return (
      dispatch(savetoppingsSuccess(alltoppings))
    );
  };
}

// export function handleSaveAllOrderStatus(orderStatuses) {
//   return (dispatch) => {
//     return (
//       dispatch({
//         type: actionTypes.SAVE_ORDER_STATUS_SUCCESS,
//         payload: { orderStatuses },
//       })
//     );
//   };
// }

export function handleUpdateAuthenticatedStatus(authenticated) {
  return (dispatch) => {
    return (
      dispatch({
        type: actionTypes.UPDATE_AUTHENTICATED_STATUS,
        payload: { authenticated },
      })
    );
  };
}
export function handleSetshowLogout(logout) {
  return (dispatch) => {
    return (
      dispatch({
        type: actionTypes.SET_SHOW_LOGOUT,
        payload: { logout },
      })
    );
  };
}
export function saveCurrentUrl(currentUrl) {
  return (dispatch) => {
    return (
      dispatch(saveCurrentUrlSuccess(currentUrl))
    );
  };
}
export default actionTypes;
