import { Hidden } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { appHelpers } from "../../../appHelpers";
import eye from "../../../assets/images/eye.svg";
// import facebook_logo from "../../../assets/images/facebook_logo.svg";
// import google from "../../../assets/images/google.svg";
import signin_img from "../../../assets/images/image4.png";
import login_arrow from "../../../assets/images/login-arrow.svg";
import logo from "../../../assets/images/logo.svg";
import mail from "../../../assets/images/mail.svg";
import padlock from "../../../assets/images/padlock.svg";
import CustomTilt from "../../../components/Animation/CustomTilt";
import CustomButton from "../../../components/Input/CustomButton";
import CustomCheckBox from "../../../components/Input/CustomCheckBox";
import CustomInput from "../../../components/Input/CustomInput";
import SpaceTop from "../../../components/Space/SpaceTop";
import { handleSetshowLogout, handleUpdateAuthenticatedStatus, saveUserData } from "../../../logic/actions/actionTypes";
import { LoginApiService } from "../../../services/auth";
import { handleError } from "../../../services/error-handler";
import { loginErrors } from "../../../utils/validation";
import usecommonStyles from "../genericAuthStyle";

const SignIn = (props) => {
  const {history} = props;
  const [showPassword, setshowPassword] = useState(false);
  const commonClasses = usecommonStyles();
  const dispatch = useDispatch()
  const hasError = useRef(null);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [err, setError] = useState(loginErrors);
  const [loading, setLoading] = useState(false);
  const [disableLogin, setdisableLogin] = useState(false);
  const toggleShowPassword = () => {
    setshowPassword(!showPassword);
  };


  const { mutate, isLoading } = useMutation(LoginApiService, {
    onSuccess: (res) => {
      const { success, data } = res.data;
      if(data){
        if(data.message){
          history.push({
            pathname: '/verify-account',
            state: { otp: data.message.otp,email:inputs.email }
          })
        }
      }else{
         
          if(res.data.status === "ok"){
            localStorage.setItem('V-AUTH',res.data.token)
            dispatch(saveUserData(res.data));
        history.push("/home/dashboard");
          }
        
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      console.log("Error", response.message);
      appHelpers.toastError(4500,response?.message ?? 'Something happened, please try again.')

    },
  });

  const handleInputChange =
    (input, label) =>
    ({ target: { value } }) => {
      setInputs((prevState) => ({
        ...prevState,
        [input]: value,
      }));
      props.validator(
        { name: input, value: value, label: label },
        "Login",
        setError,
        err
      );
    };
  const handleLogin = () => {
    setdisableLogin(true);
    setLoading(true);
    props.validatorAll(
      [
        { name: "email", value: inputs.email, label: "Email" },
        { name: "password", value: inputs.password, label: "Password" },
      ],
      "Login",
      setError,
      err,
      hasError
    );
    if (!hasError.current) {
      setLoading(false);

      setdisableLogin(false);
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      setdisableLogin(false);
      setLoading(false);
      return;
    }
    if (err.email && err.password === "") {
      setLoading(false);
      setdisableLogin(false);
    }
    const emailValue = inputs.email
    LoginApiService({...inputs}).then((res)=>{
      const { success, data } = res.data;
      dispatch(handleSetshowLogout(false))
      if(data){
        if(data.message){
          history.push({
            pathname: '/verify-account-auth',
            state: {email:emailValue }
          })
        }
      }else{
        const encrypted = appHelpers.cryptographyService().encrypt(res.data.token)
         dispatch(handleUpdateAuthenticatedStatus(true))
          if(res.data.status === "ok"){
            localStorage.setItem('V-AUTH',encrypted)
            dispatch(saveUserData(res.data));
        history.push("/home/dashboard");
          }
        
      }
    })
      .catch ((error) => {
        const { response } = handleError(error);
        setLoading(false);
        setdisableLogin(false);
        appHelpers.toastError(4500,response?.message ?? 'Something happened, please try again.')
  
    
    });
  };
  return (
    <div className="container p-3">
      <div className="grouped-content">
      <Link to={"/"}><img src={logo} alt="logo" /></Link>
        <p>
          Do not have an account?&nbsp;
          <span>
            <Link className="memor"  to={`/create-account`}>
              Sign up
            </Link>
          </span>
        </p>
      </div>
      <SpaceTop length={70} />
      <Grid container spacing={5} justify="space-between" direction="row">
        <Grid item xs={12} sm={5}>
          <h3>
            <b>Welcome Back</b>
          </h3>
          <p className={commonClasses.subText}>
            Fill in the details below to have full access to memo
          </p>
          <div>
          <SpaceTop length={70}/>
            {/* <div className="d-flex flex-wrap justify-content-between">
              <CustomButton
                show
                text="Google"
                style={{
                  backgroundColor: "#ea4335",
                  color: "white",
                  width: "45%",
                  padding: "26px",
                  border: "1px #395484",
                }}
                startIcon={
                  <img
                    className="mr-2"
                    style={{ width: "23px", height: "23px" }}
                    src={google}
                    alt="Google"
                  />
                }
              />
              <CustomButton
                show
                style={{
                  backgroundColor: "#395485",
                  color: "white",
                  padding: "26px",
                  width: "45%",
                  border: "1px #395484",
                }}
                startIcon={
                  <img
                    className="mr-2"
                    style={{ width: "23px", height: "23px" }}
                    src={facebook_logo}
                    alt="facebook"
                    width="40%"
                  />
                }
                text="Facebook"
              />
            </div> */}
            {/* <div className="d-flex flex-row mt-3 mb-3">
              <HorizonalRuler />
              &nbsp;&nbsp;&nbsp;
              <p className="pl-3 pr-3">or</p>&nbsp;&nbsp;&nbsp;
              <HorizonalRuler />
            </div> */}
            <CustomInput
              errorText={err.email}
              showError={err.email.length > 0}
              value={inputs.email}
              onChange={handleInputChange("email", "Email")}
              startIcon={<img src={mail} alt="mail" />}
              placeholder="Email address"
            />
            
            <CustomInput
              errorText={err.password}
              showError={err.password.length > 0}
              value={inputs.password}
              type={showPassword ? 'text':'password'}
              onChange={handleInputChange("password", "Password")}
              startIcon={<img src={padlock} alt="padlock" />}
              endIcon={
                !showPassword ? (
                  <img
                    src={eye}
                    alt="show-password"
                    onClick={toggleShowPassword}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    onClick={toggleShowPassword}
                    style={{ cursor: "pointer", color: "#979694" }}
                  />
                )
              }
              placeholder="Password"
            />
          </div>
          &nbsp;
          <div className="grouped-contents">
            <CustomCheckBox label="Remember me" color="#eea530" />
            <Link className="memor" to="/forgot-password">
              Forgot password?
            </Link>
          </div>
          <CustomButton
            show
            onClick={handleLogin}
            loading={loading }
            disabled={disableLogin}
            style={{
              backgroundColor: "#eea350",
              color: "white",
              width: "100%",
            }}
            endIcon={
              <img
                className="mr-2"
                style={{ width: "23px", height: "23px" }}
                src={login_arrow}
                alt="login"
              />
            }
            text="Login"
          />
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={6} className="text-center mt-auto">
            <CustomTilt>
              <img src={signin_img} alt="signin" width="80%" />
            </CustomTilt>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default SignIn;
