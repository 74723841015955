import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import memobrand from '../../assets/images/memobrand.svg';
import CustomButton from '../../components/Input/CustomButton';
import "./error.css";
export const ErrorPage = ({caption,description}) => {
	useEffect(()=>{
		document.body.style.backgroundColor = 'white'
	},[])
    return (
        <>
      <div>
	  <section className="page_404">
		 
	<div className="container">
		<div className="row">	
		<Link  to={"/"}><img src={memobrand} style={{width:'210px'}}/></Link>
		<div className="col-sm-12 ">
		<div className="text-center">
		<div className="four_zero_four_bg">
			<h1 className="text-center ">404</h1>
		
		
		</div>
		
		<div className="contant_box_404">
		<h3 className="h2">
		{caption}
		</h3>
		
		<p>{description}</p>
		
		<CustomButton 
		text="Refresh"
		onClick={()=>window.location.reload()}
		/>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
	  </div>
        </>
    )
}