import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
import { Link } from "react-router-dom";
import DEMO, { Colors } from "../../../Constants";
import actionTypes, { handleSetshowLogout } from "../../../logic/actions/actionTypes";
import { CustomAvatar } from "../../Avatar/CustomAvatar";
import Aux from "../../hoc/_Aux";
import ChatList from "./ChatList";


class NavRight extends Component {
  state = {
    listOpen: false,
  };

  componentDidMount = () => {

  }
  logout = () => {
    this.props?.dispatch(handleSetshowLogout(true))

  };
  render() {
    const userdata = this.props.userdata && this.props.userdata;
    // console.log("userdtaa in navrught", userdata);
    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', this.props.headerBackColor];
    if (this.props.headerFixedLayout) {
        headerClass = [...headerClass, 'headerpos-fixed'];
    }

    let toggleClass = ['mobile-menu'];
    if (this.props.collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
      <Aux>
        <ul className="navbar-nav ml-auto">
          {/* <li>
                        <Dropdown alignRight={!this.props.rtlLayout}>
                            <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                                <i className="icon feather icon-bell"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight className="notification">
                                <div className="noti-head">
                                    <h6 className="d-inline-block m-b-0">Notifications</h6>
                                    <div className="float-right">
                                        <a href={DEMO.BLANK_LINK} className="m-r-10">mark as read</a>
                                        <a href={DEMO.BLANK_LINK}>clear all</a>
                                    </div>
                                </div>
                                <ul className="noti-body">
                                    <li className="n-title">
                                        <p className="m-b-0">NEW</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar1} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>John Doe</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>New ticket Added</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="n-title">
                                        <p className="m-b-0">EARLIER</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar2} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>Joseph William</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>Prchace New Theme and make payment</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar3} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>currently login</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="noti-footer">
                                    <a href={DEMO.BLANK_LINK}>show all</a>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
          {/* <li className={this.props.rtlLayout ? 'm-r-15' : 'm-l-15'}>
                        <a href={DEMO.BLANK_LINK} className="displayChatbox" onClick={() => {this.setState({listOpen: true});}}><i className="icon feather icon-mail"/></a>
                    </li> */}
          <li style={{ display: "flex" }}>
            <div className="d-inline-flex">
             <div>
                <Dropdown
                  alignRight={!this.props.rtlLayout}
                  style={{ marginLeft: "30px" }}
                  className="drp-user"
                >
                  <Dropdown.Toggle variant={"link"} id="dropdown-basic">
                    {/* <i className="icon feather icon-settings"/> */}

                    <div className="d-inline-flex"> <div><CustomAvatar
                      fullname={`${userdata.last_name} ${userdata.first_name}`}
                      round
                      size="40"
                      color={Colors.Yellow}
                    /></div>
                      <div> <label style={{marginLeft:'5px'}} className="cursor-pointer"><b>{userdata.last_name}</b></label></div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight className="profile-notification cursor-pointer" style={{minWidth:'150px'}}>

                    <ul className="pro-body">
                      <li>
                        <Link to="/home/settings" className="dropdown-item">
                          <i className="feather icon-settings" /> Settings
                      </Link>
                      </li>
                      <li onClick={this.logout} >
                        <a href={DEMO.BLANK_LINK} className="dropdown-item">
                          <i className="feather icon-log-out" style={{color:Colors.Red}} /> Log Out
                      </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </div>{ <MediaQuery maxDeviceWidth={1224}><a className={toggleClass.join(' ')} id="mobile-collapse1" href={DEMO.BLANK_LINK} style={{position:'relative',marginLeft:'10px'}} onClick={this.props.onToggleNavigation}><span/></a></MediaQuery>}

          </li>
          
        </ul>
        <ChatList
          listOpen={this.state.listOpen}
          closed={() => {
            this.setState({ listOpen: false });
          }}
        />
      </Aux>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     userdata: state.authReducer.userdata,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   dispatch, // ← Add this
// });

// export default connect(mapStateToProps, mapDispatchToProps)(NavRight);
const mapStateToProps = state => {
  return {
      userdata: state.authReducer.userdata,
      rtlLayout: state.navigation.rtlLayout,
      headerBackColor: state.navigation.headerBackColor,
      headerFixedLayout: state.navigation.headerFixedLayout,
      collapseMenu: state.navigation.collapseMenu
  }
};

const mapDispatchToProps = dispatch => {
  return {
      dispatch,
      onToggleNavigation: () => dispatch({type: actionTypes.COLLAPSE_MENU}),
  }
};

export default connect(mapStateToProps, mapDispatchToProps) (NavRight);

