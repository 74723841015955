import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import CustomButton from "../../components/Input/CustomButton";

import "./modal.scss";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ width: "480px" }} />
    </Draggable>
  );
}

export default function ActionModal({
  showCancel,
  show,
  hideModal,
  title,
  image,
  description,
  canceling,
  cancelText,
  loading,
  okText,
  showok,
  handleOk,
  handleCancel
}) {
  const [open, setOpen] = React.useState(show);

  const handleClose = () => {
    hideModal();
  };
  let closeImg = {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <div>
            {title}
            <img
              alt="dialog-close"
              onClick={handleClose}
              src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
              style={closeImg}
            />
          </div>
        </DialogTitle>
        <DialogContent
          dividers
          className="dialog-content"
          style={{ borderTop: 0 }}
        >
          <DialogContentText component={"div"}>
            <div>
              <div className="d-flex justify-content-center">
                <img src={image} width="50%" alt="Delete" />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <div className="d-flex justify-content-center mt-5">
          <h5>{description}</h5>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <div className="d-inline-flex">
            <div>{showCancel&& <CustomButton
              show={showCancel}
              type="cancel"
              loading={canceling}
              onClick={handleCancel}
              text={cancelText || 'Cancel'}
            />}</div>&nbsp;&nbsp;
            <div> {showok&&<CustomButton
              show={showok}
              loading={loading}
              onClick={handleOk}
              text={okText}
            />}</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
