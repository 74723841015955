import actionTypes from "../actions/actionTypes";

const initialState = {
  allflavours: [],
};


const flavourReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_FLAVOURS_SUCCESS:
      return {
        ...state,
        allflavours: action.payload.allflavours,
      };
    default:
      return state;
  }
};

export default flavourReducer;
