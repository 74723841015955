const items =  {
 items : [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "icon-navigation",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        url: "/home/dashboard",
        icon: "fa fa-home",
        permission: ["CanViewDashboard"],
        code: "CanViewDashboard"
      }
    ]
  },
  {
    id: "cakes",
    title: "Cakes",
    type: "item",
    url: "/home/cakes",
    icon: "fa fa-birthday-cake",
    code :"CanViewCakes"
  },
  {
    id: "gifts",
    title: "Gifts",
    type: "item",
    url: "/home/gifts",
    icon: "fa fa-gift",
    code :"CanViewGifts"
  },
  {
    id: "bankaccounts",
    title: "BankAccounts",
    type: "item",
    url: "/home/bank-accounts",
    icon: "fa fa-bank",
    code :"CanViewBankAccounts"
  },
  {
    id: "withdrawals",
    title: "WithDrawals",
    type: "item",
    url: "/home/withdrawals",
    icon: "fa fa-get-pocket",
    code :"CanViewWithDrawals"
  },
  {
    id: "orders",
    title: "Order",
    type: "item",
    url: "/home/orders",
    icon: "fa fa-cube",
    code :"CanViewOrders"
  },
]
}

export default items;