import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import windowSize from 'react-window-size';
import { matchPath } from 'react-router';
import Aux from "../../../hoc/_Aux";
import NavIcon from "./../NavIcon";
import NavBadge from "./../NavBadge";
import actionTypes from "../../../../logic/actions/actionTypes";

class NavItem extends Component {

    render() {
        let itemTitle = this.props.item.title;
        if (this.props.item.icon) {
            itemTitle = <span className="pcoded-mtext">{this.props.item.title}</span>;
        }

        let itemTarget = '';
        if (this.props.item.target) {
            itemTarget = '_blank';
        }

        let subContent;
        if(this.props.item.external) {
            subContent = (
                <a href={this.props.item.url} target='_blank' rel='noopener noreferrer'>
                    <NavIcon items={this.props.item}/>
                    <NavBadge layout={this.props.layout} items={this.props.item}/>
                </a>
            );
        } else {
            // subContent = (
            //     <NavLink to={this.props.item.url} className="nav-link" exact={true} target={itemTarget}>
            //         <NavIcon items={this.props.item}/>
            //         {itemTitle}
            //         <NavBadge layout={this.props.layout} items={this.props.item}/>
            //     </NavLink>
            // );
            const isSelected = matchPath(this.props.location.pathname,this.props.item.url);
            //let isSelected = this.props.sideRoute === this.props.item.url ? true : false;
            let {color,headerTitle,subMenus, menuSideColor,url} = this.props.item;
            subContent = (
                <NavLink to={this.props.item.url}  className="nav-link"
                style={({ color : isSelected && ' #EEA530', backgroundColor: '#ffffff' , borderRight: this.props.item.type !== "group" ? ( isSelected ? `4px solid #EEA530`:"0" ): ""}  )  }
                exact={true} id="realLink" target={itemTarget}>
                    <NavIcon items={this.props.item}/>
                    {itemTitle}
                    <NavBadge layout={this.props.layout} items={this.props.item}/>
                </NavLink>
            );
        }
        let mainContent = '';
        if (this.props.layout === 'horizontal') {
            mainContent = (
                <li onClick={this.props.onItemLeave}>{subContent}</li>
            );
        } else {
            if (this.props.windowWidth < 992) {
                mainContent = (
                    <li className={this.props.item.classes} onClick={this.props.onItemClick}>{subContent}</li>
                );
            } else {
                mainContent = (
                    <li className={this.props.item.classes}>{subContent}</li>
                );
            }
        }

        return (
            <Aux>
                {mainContent}
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.navigation.layout,
        collapseMenu: state.navigation.collapseMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onItemClick: () => dispatch({type: actionTypes.COLLAPSE_MENU}),
        onItemLeave: () => dispatch({type: actionTypes.NAV_CONTENT_LEAVE})
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (windowSize(NavItem)));
