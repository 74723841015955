import { AUTH, FORGOT, LOGIN, OTP, PASSWORD, USERS, VERIFY } from '../Constants';
import Axios, { getRestUrl, getSingleUrl } from './axios';



export const LoginApiService = (data) => {
    return Axios.post(getRestUrl(AUTH,LOGIN), data);
};
export const ChangePasswordApiService = (data) => {
    const id = data.id
    delete data.id
    const payload = data
    return Axios.put(`${getSingleUrl(USERS)}/${id}`, payload);
};

export const ForgotPasswordApiService = (data) => {
    return Axios.post(getRestUrl(PASSWORD,FORGOT), data);
};
export const CreateAccountApiService = (data) => {
    return Axios.post(getSingleUrl(USERS), data);
};
export const ResetPasswordApiService = (data) => {
    return Axios.post(getRestUrl(PASSWORD,FORGOT), data);
};

export const OTPApiService = (data) => {
    return Axios.post(getRestUrl(AUTH,OTP), data);
};
export const VerifyApiService = (data) => {
    return Axios.post(getRestUrl(AUTH,VERIFY), data);
};

