import React from "react";
// import { appHelpers } from "appHelpers";
import { connect } from "react-redux";
import { PURGE } from 'redux-persist';
import { appHelpers } from "../../appHelpers";
import logoutimage from "../../assets/images/logoutimage.svg";
// import "../../assets/scss/style.scss";
import * as CommonFunctions from "../../CommonFunctons";
import PreLoader from "../../components/Loader/PreLoader";
import {
  handleSetshowLogout,
  saveUserData
} from "../../logic/actions/actionTypes";
import { fetchAllOrderStatuses } from "../../logic/actions/requests";
import returnStoreAndPersistor from '../../logic/configureStore';
// import { permissionstuff } from "./../../permissionstuff";
import { ErrorPage } from "../../Pages/NotFound/ErrorPage";
import ActionModal from "../Modal/ActionModal";
import Wrapper from "../Wrapper/Wrapper";

class AuthenticatedLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { userobj: {} },
      token: "",
      sending: false,
      page: {},
      showSideBar: false,
      permissions: [],
      ServiceBase: null,
      error: false,
      unAuthorized: null,
      expiresIn: parseInt(this.props.expiresIn),
    };
  }

  componentWillMount() {
    // check if the user is authenticated. e.g if the token exist in localStorage or any where else
    // if token doesn't exist, reroute to login
  }
  async componentDidMount() {
    this.setState({ sending: true, error: false });
    const { USERS, ME } = this.props.Constants;
    // this.props.dispatch()
    const realToken = localStorage.getItem("V-AUTH");
    if (realToken) {
      const encryptedToken = appHelpers
        .cryptographyService()
        .decrypt(realToken);

      const Authtoken = `Bearer ${encryptedToken}`;
      if (this.props.userdata.token) {
        const permissions = {
          CanViewDashboard: true,
          CanViewOrders: true,
          CanViewCakes: true,
        };
        const allCodes = [
          "CanViewDashboard",
          "CanViewOrders",
          "CanViewCakes",
          "CanViewGifts",
          "CanViewBankAccounts",
          "CanViewWithDrawals",
        ];
        this.props
          .Service(Authtoken, this.props.history)
          .getInfo(USERS, ME)
          .then(({ data }) => {
            const userObj = {
              ...this.props.userdata,
              ...data.data,
            };
            this.props.dispatch(saveUserData(userObj));
            this.setState(
              {
                ServiceBase: this.props.Service(Authtoken, this.props.history),

                validator: CommonFunctions.validator,
                validatorAll: CommonFunctions.validatorAll,
                permissions,
                codes: allCodes,
              },
              () => {
                this.setState({ sending: false, error: false });
                this.props.dispatch(
                  fetchAllOrderStatuses(
                    this.state.ServiceBase,
                    this.props.Constants,
                    this.props.history
                  )
                );
              }
            );
          })
          .catch((err) => {
            if (err) {
              if (err.response && err.response.status === 401) {
              }
              this.setState({ error: true, sending: false });
            }
          });
      } else {
        appHelpers.toastError(
          4500,
          "No authorization provided. please login again."
        );
        this.props.history.push("/");
      }
    } else {
      appHelpers.toastError(
        4500,
        "No authorization provided. please login again."
      );
      this.props.history.push("/");
    }
  }

  logout = () => {
    this.props?.dispatch(handleSetshowLogout(false));
    const {persistor} = returnStoreAndPersistor();
    persistor.purge()
    this.props.dispatch({ 
      type: PURGE,
      key: "root",    // Whatever you chose for the "key" value when initialising redux-persist in the **persistCombineReducers** method - e.g. "root"
     result: () => null              // Func expected on the submitted action. 
  }); 
    localStorage.clear();
    this.props.history.push("/");
  };
  handleCancelLogout = () => {
    this.props?.dispatch(handleSetshowLogout(false));
  };

  render() {
    return (
      <div>
        {this.state.error && (
          <ErrorPage
            caption="Check your network"
            description="Oops we tried all we could, kindly hit refresh to try again."
            {...this.props}
            unAuthorized={this.state.unAuthorized}
          />
        )}
        {this.state.sending && (
          <div>
            <PreLoader />
          </div>
        )}

        {!this.state.sending && this.state.ServiceBase && (
          <Wrapper {...this.state} {...this.props} />
        )}

        {this.props.logout === true && (
          <ActionModal
            show={this.props.logout}
            hideModal={this.handleCancelLogout}
            handleCancel={this.handleCancelLogout}
            handleOk={this.logout}
            okText="Yes logout"
            image={logoutimage}
            description="Are you sure you want to logout? "
            cancelText="Cancel"
            showCancel={true}
            showok={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userdata: state.authReducer.userdata,
    expiresIn: state.authReducer.expiresIn,
    logout: state.authReducer.logout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch, // ← Add this
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedLayout);
