export const permissionstuff = {
  isAuthorized: (all) => {
    const permissions = {
      // dashboard
      CanViewDashboard: all.includes("CanViewDashboard") ? true : false,

      // Orders
      CanViewOrders: all.includes("CanViewOrders") ? true : false,


      // Cakes
      CanViewCakes: all.includes("CanViewCakes") ? true : false,

    }
    return permissions;
  },

  filterSideMenu: (all, items) => {
    // eslint-disable-next-line array-callback-return
    var res = items.filter(function f(o) {
      if (all.includes(o.code)) return true;

      if (o.children) {
        return (o.children = o.children.filter(f)).length;
      }
    });

    return res;
  },
};
