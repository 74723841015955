import dayjs from 'dayjs';
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatCakeSizes = (fields, vendor_id, cake_id) => {
  let sizes = [];
  for (let i in fields) {
    sizes.push({
      size: fields[i].size,
      price: parseInt(fields[i].price),
      vendor_id: vendor_id,
      cake_id: cake_id,
    });
  }
  return sizes;
};
export const returnPayStackUrl =()=>{
  if(window.location.hostname.includes("dev")){
    return process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_TEST
  }else if(window.location.hostname.includes("localhost") ){
    return process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_TEST
  }else{
    return process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_LIVE

  }
}
export const returnApiUrl =()=>{
  if(window.location.hostname.includes("dev")){
    return process.env.REACT_APP_SERVICE_BASE_URL_TEST
  }else if(window.location.hostname.includes("localhost") ){
    return process.env.REACT_APP_SERVICE_BASE_URL_TEST
  }else{
    return process.env.REACT_APP_SERVICE_BASE_URL_LIVE

  }
}
export const returnCakeSizesString = (allsizes) => {
  let sizes = [];
  if (allsizes && allsizes.length > 0) {
    for (let i in allsizes) {
      sizes.push(`${allsizes[i]?.size} inches`);
    }
    return sizes.toString();
  } else {
    return `empty`;
  }
};
export const returnCakePricesString = (allsizes) => {
    let sizes = [];
  if (allsizes && allsizes.length > 0) {
    for (let i in allsizes) {
      sizes.push(`₦ ${allsizes[i]?.price}`);
    }
    return sizes.toString();
  } else {
    return `empty`;
  }
};

export const formatLabels = (dataLabels)=>{
  const formatted = []
  for (let i in dataLabels){
    formatted.push(dayjs(dataLabels[i]).format('dddd'))
  }
  return formatted
}