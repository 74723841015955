import { green } from "@material-ui/core/colors";
import InputBase from "@material-ui/core/InputBase";
import {
  createMuiTheme, fade,


  makeStyles, withStyles
} from "@material-ui/core/styles";
import React from "react";
import { Form } from "react-bootstrap";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "239px",
    padding: "11px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#4431B4"
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

export default function CustomInput({type,labelText,eyeIconTop='14px',onChange,width,startIcon,
errorText, text,disabled,showError,value,placeholder,endIcon
}) {
  const classes = useStyles();
  let newtype ;
  if(type === "password"){
    newtype = "password"
  }else if(type === "text"){
    newtype = "text"
  }else if(type === "number"){
    newtype = "number"
  }else{
    newtype = type || 'text'
  }
  return (
    <div className="input-group" style={{ background: "0" }}>
      <Form.Group
        controlId="exampleForm.ControlInput1"
        
        style={{ width: "-webkit-fill-available",marginBottom:'20px' }}
      >
        <Form.Label style={{ float: "left" }}>{labelText}</Form.Label>
        <div
          style={{
            position: "absolute",
            marginRight: "20px",
            marginLeft:'10px',
            top: "14px",
            cursor: "pointer",
          }}
        >
          {startIcon}
        </div>
        <Form.Control
          type={newtype}
          isInvalid={showError}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          style={{ background: "#FFFFFF", width:width ,paddingLeft:startIcon && '40px',marginBottom:0}}
          disabled={disabled}
        />
        <Form.Control.Feedback
          type="invalid"
          style={{ marginBottom: "-1rem", textAlign: "left" }}
        >
          {errorText}
        </Form.Control.Feedback>

        <div
          >
          {text}
        </div>
        <div
          style={{
            position: "absolute",
            right: "15px",
            top: eyeIconTop,
            cursor: "pointer",
          }}
        >
          {endIcon}
        </div>
      </Form.Group>
    </div>
  );
}
