import { makeStyles } from "@material-ui/core";

const usecommonStyles = makeStyles((theme) => ({
    subText:{
      color:'#5A7184'
    },
  }));


  export const useStyles = makeStyles(theme => ({
    textField: {
      '& .MuiSelect-select': {
        backgroundColor: '#fff',
        borderColor: "#f8f8f8"
      },
      '& .MuiOutlinedInput-root': {
        fontSize: 14,
      },
    },
  }))

export default usecommonStyles