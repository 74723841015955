import axios from 'axios';
import { appHelpers } from '../appHelpers';
import { returnApiUrl } from '../utils/globalUtil';

//TODO: Remember to change baseURL to .env variable
const Axios = axios.create({
  // baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
  baseURL:returnApiUrl()
});

Axios.interceptors.request.use(
  async (config) => {
    const token = await appHelpers.getDefaultAuth()
    if (token) config.headers['Authorization'] = 'Bearer ' + token + '';
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);




export const getRestUrl = (controller, action) => {
  return `${controller}/${action}`;
};

export const getSingleUrl = (controller) => {
  return `${controller}`;
};

export default Axios;
